import React from 'react'
import styled from 'styled-components';

//import { ThemeContext } from '../context'

const Component = (props) => {
  //const themeContext = useContext(ThemeContext)

  const Container = styled.div`
    position:relative;
  `

  const Video = styled.video`
    position:relative;
    z-index:0;
    margin: 0; 
    padding: 0;
  `

  const Overlay = styled.div`
    position:absolute;
    top:0;
    left:0;
    z-index:1;

    background-color: rgba(0, 0, 0,.5);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  `

  const TopGap = styled.div`
  `

  //const Elements = <div>data=>{ JSON.stringify(props.data.elements)}</div>
  /*
  props.data.elements.slice(0).reverse().map((element, element_idx)=>{
    switch(element.type){
      case 'P': 
        return <P key={element_idx} data={element}/>
      case 'BoxesList':
        return <BoxesList key={element_idx} data={element}/>
      default:
        return <div key={element_idx} data={element}>{`unknown element type (${element.type})`}</div>
    }
  })*/
//${themeContext.css[props.data.theme]}
//${props.data.style}

  const Sources = props.data.sources.map((item, index)=>{
    return <source src={item.src} type="video/mp4"/>
  })
  
  return (
    <Container>
      <div>{JSON.stringify(props.data)}</div>
      <Video id="video" width="100%" height="100%" autoPlay loop>
        {Sources}
        {props.data.browser_support_err}
      </Video>
      <Overlay>
        <TopGap/>
        {JSON.stringify(props.data.sources)}
        {props.data.browser_support_err}
      </Overlay>
    </Container>
  )
} 

export default Component
