import React, {useContext} from 'react'
import styled from 'styled-components';

import { ThemeContext } from '../context'
import A from './A'

const Component = (props) => {
  const themeContext = useContext(ThemeContext)

  const P = styled.h1`
    ${themeContext.css[props.data.theme]}
    ${props.data.style}
  `

  return (
    props.data.link_to?
    <A data={{link_to: props.data.link_to}} style={{cursor: 'pointer'}}><P>{props.data.text}</P></A>
    :
    <P style={{cursor: 'default'}}>{props.data.text}</P>
  )
}

export default Component
