import React from 'react'
import styled from 'styled-components';

//import { ThemeContext } from '../context'
import P from './P'
import BoxesList from './BoxesList'

const Component = (props) => {
  //const themeContext = useContext(ThemeContext)

  const Section = styled.div`
    position:relative;
  `

  const Video = styled.video`
    position:relative;
    z-index:0;
    margin: 0; 
    padding: 0;
  `

  const Overlay = styled.div`
    position:absolute;
    top:0;
    left:0;
    z-index:1;

    background-color: rgba(0, 0, 0,.5);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  `

  const TopGap = styled.div`
  `

  const data = {

    menu_title: `Welcome`, 
    elements: [{
      type: 'P', theme: `header_sub`, text: `Welcome to Emaxer`, style: `
      
      font-weight: bold;

      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: 0 0;

      color: #f48c34;

      line-height: 1.25em;
      letter-spacing: normal;
      pointer-events: auto;
      font: normal normal normal 72px/1.25em 'playfair display',serif;
      word-wrap: break-word;

      text-align: left;
      padding-left: 15%;
      padding-right: 15%;   
      `
    },{
      type: 'P', theme: `header_title`, text: `We focus on your IT, so you can focus on your Business`, style: `
      
      color: #241c24;

      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: 0 0;

      text-align: center;
      line-height: 1.35em;

      letter-spacing: normal;

      pointer-events: auto;

      font: normal normal normal 40px/1.35em 'playfair display',serif;

      color: #fff;
      text-align: left;
      padding-left: 15%;
      padding-right: 15%;
      margin-top: 35px;
      `
    },{
      type: 'P', theme: `header_detail`, text: `Emaxer will provide you with practical, reliable and cost-effective solutions for all your IT needs, utilizing the latest stable and proven technologies, driven by professionals and consultants.`, style: `
      font-size: 16px;

      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: 0 0;

      font-weight: 300;
      
      font-family: helveticaneuew01-55roma,helveticaneuew02-55roma,helveticaneuew10-55roma,helvetica neue,helvetica,arial,meiryo,hiragino kaku gothic pro w3,hiragino kaku gothic pro,sans-serif;
      
      line-height: 1.75em;
      text-align: center;

      letter-spacing: normal;

      pointer-events: auto;

      color: #fff;
      text-align: left;
      padding-left: 15%;
      padding-right: 15%;
      margin-top: 35px;
      padding-bottom: 20px;
    `,
    }],
    theme: `section_dark_bg`,

  }

  const Elements = data.elements.slice(0).reverse().map((element, element_idx)=>{
    switch(element.type){
      case 'P': 
        return <P key={element_idx} data={element}/>
      case 'BoxesList':
        return <BoxesList key={element_idx} data={element}/>
      default:
        return <div key={element_idx} data={element}>{`unknown element type (${element.type})`}</div>
    }
  })
//${themeContext.css[props.data.theme]}
//${props.data.style}
  return (
    <Section>
      <Video width="100%" height="100%" autoPlay loop>
        <source src="https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/emaxer-welcome.mp4" type="video/mp4"/>
        {/*<source src="https://www.w3schools.com/html/movie.ogg" type="video/ogg"/>*/}
        {/*<source src="https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/emaxer-welcome.mov" type="video"/>*/}
        Your browser does not support the video tag.
      </Video>
      <Overlay>
        <TopGap/>
        {Elements}
      </Overlay>
    </Section>
  )
} 

export default Component
