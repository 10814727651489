import React from 'react'
import styled from 'styled-components'
//import { ThemeContext } from '../context'
import Section from './Section'

const Component = (props) => {
  //const themeContext = useContext(ThemeContext)

  const fixed_css = (fixed) => {
    if(fixed){
      return `
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index:10000;
      `
    } else {
      return `
        z-index:10000;
      `
    }
  }
//console.log(props.data.style)
  const Footer = styled.div`
    ${fixed_css(props.data.fixed)}

    text-align: center;
    margin: 0;
    padding: 0;
`
  const icon_size= '25px'
  return (
    <Footer style={props.data.style}>

      <div class="social-media-icons" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 100, backgroundColor: 'rgba(244, 140, 52, 1)' }}>

        <a href="https://www.facebook.com/Emaxer-100066875356019" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733547.png" alt="Facebook" style={{ width: icon_size, height: icon_size, margin: '0 10px', textDecoration: 'none' }} />
        </a>

        <a href="https://www.linkedin.com/company/766381" target="_blank" rel="noopener noreferrer">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAb1BMVEUueLb///8gcrMqdrUIbLFMiL7z+Pv7/P6Zu9ra6PIXb7LE1ugecbPe5/F9q9GjxN+TtdVVkcO4z+Q4gbzq8ffM2+vj7PRhmsjV4u8zfbluocyuyOGIsdRBhr7R4O680eV4ps5elsarwtxvncmwzOS9v/3QAAADyUlEQVR4nO3da3OiMBiGYZKsCUJEEDwBAmr//29cRLdbJa/rDpaUzHN9LZ3hbjgG2PU8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAcQnBdYsL2yvyXbSX+6swLMpDwG2vy3fQ+6VUirWU3OXauXEUqd/V/bHbCrcaRRKye7K0vU5vJZKK9fgu7Yzp4wh2IncS9dIUyDaJK7uiSJWxkNWuDGLwYQ5k2daRQUwlUcgKbXvd3oJHxEbKWJXaXrm30AUVyBZubKbBmizMGieONcGCLJRunBKDnfOFxgsal7ZS4ormYn504kgjzuTZIvTcKPQyIlD5bpzxPe0The5celODuHTiOHPBI2PgLnVmCIVnOpyqmTOBlzvE/g2U2jqzjV4I4T/cQs0TpwJbIq/+NqpNcXBoE73hXhTOM6mU3KxPRydn9gUXSVPGcXlO3ZvxvhGCX7iaBwAwKd0xWVzYXpNvILjmhyTPmybfHmfej3kpgJvdr9ztbPlsIaHT6GO32GSdzWZRrcqE/4DrB95ERne3+GKfm5ZpPhcSPNjW8nHOR8lseQhsb6+/iEczd0+BxWxuWkbdFmobGmq+h1Vnz24jUaheKZTXhdoRpieWW+HRUtvVkMLrQmK2JB/R3f4S8d7iKA4u5Ef66c6nwuK0z9BCfjT+6NHKXuLAQr59KbBN3E+zMDg8PcZ8XdbaBOywQo9+dvXI2rOsYYUx+WCnr7I0BzuoMHx1G+3EVgIHFbL/GMHW3M4gDir8T5HzhWsrlzZjFjIrm+mohbGNE8aohfPA9UJm4+2H9xUqKf9xF8WYjXfl3lMoq9UpLsv4VKyfVtp4f/wdhXLVJPvr3JQ3a+on1wFrCzviGwo3efo5PSoET2NyzoZlkyycHx5nHktyS5UW7hIHF2a946PQPrWhKgvvIQ0uNB0e99Q9h8qnV/hhutbkJTGIqhz/YDqwUJ6Ng7InDjbKwuliYGFofjMlqInlLXzjMLDQN+9XxNtyjK2mVki9DC4SojCcWiH1qrTgRGE1/il/WCGxG9KPtHZTK6ypeQnqM4fJFa6oEzj1Mc5iaoUn6vSmidPF5ArJhxGa+K5xaoX0JQr1UdzkCsnJM+pjnKkVShSicAwo7KCwD4UjQmEHhX0oHBEKOyjsQ+GIUNhBYR8KR4TCDgr7UDgiFHZQ2IfCEaGwg8I+FI4IhR0U9qFwRCjsTLpQ16FRc/e206EwLlSTLzXzaG38jcLCBxc6MHn8FzteW+oLbvwFN/97EAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+Il+A15PUatCKPBoAAAAAElFTkSuQmCC" alt="LinkedIn" style={{ width: icon_size, height: icon_size, margin: '0 10px',backgroundColor: 'White', textDecoration: 'none' }} />
        </a>

      </div>

      <Section data={props.data.section}/>
    </Footer>
  )

}

export default Component
